import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface GTMProps {
    gtmId: string | undefined;
}

declare global {
    interface Window {
        dataLayer: any[];
    }
}

export const GoogleTagManager = ({ gtmId }: GTMProps) => {
    const location = useLocation();

    // ページビューのトラッキングのみを行う
    useEffect(() => {
        // 本番環境でない場合、もしくはgtmIdが未定義の場合は何もしない
        if (gtmId === undefined) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'pageview',
            page: {
                path: location.pathname,
                search: location.search,
                title: document.title
            }
        });
    }, [location, gtmId]);

    return null;
};